import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

/**
 * インターネット特約条項一覧取得
 */
export const getInternetCovenantList = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get('/agencies/internet_covenants/', {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * インターネット特約条項登録
 */
export const postInternetCovenantMaster = async (
  file,
  internetCovenantDetails
) => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('files[0]', file);
  form.append(
    'internetCovenantDetails',
    JSON.stringify(internetCovenantDetails)
  );

  const response = await axios
    .post(`/agencies/internet_covenants/`, form, {
      headers: {
        'content-type': 'multipart/form-data',
        'access-token': accessToken,
      },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * インターネット特約条項取得
 */
export const getInternetCovenant = async internetCovenantId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/internet_covenants/${internetCovenantId}`, {
      responseType: 'blob',
      dataType: 'application/pdf',
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  if (!response) return;

  // typeの実態がapplication/octet-streamになっているためpdfに書き換える
  const blob = new Blob([response.data], { type: 'application/pdf' });

  return blob;
};

/**
 * インターネット特約条項編集
 */
export const putInternetCovenantMaster = async (
  file,
  internetCovenantDetails,
  internetCovenantId
) => {
  const accessToken = await getAccessToken();
  const form = new FormData();
  form.append('files[0]', file);
  form.append(
    'internetCovenantDetails',
    JSON.stringify(internetCovenantDetails)
  );

  const response = await axios
    .put(`/agencies/internet_covenants/${internetCovenantId}`, form, {
      headers: {
        'content-type': 'multipart/form-data',
        'access-token': accessToken,
      },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * インターネット特約条項種目別適用開始日一覧取得
 */
export const getInternetCovenantStartDateList = async insuranceTypeId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/internet_covenants/${insuranceTypeId}/start_dates`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
